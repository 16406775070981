<template>
  <main class="h-screen grid grid-cols-3 bg-base-100">
    <section class="col-span-2 bg-cover bg-center" :style="`background-image: url(${bgImg})`">
    </section>
    <section class="col-span-1 grid place-items-center">
      <button type="button" class="login-with-google-btn" :disabled="isAuthLoading" @click="handleSignIn">
        Sign in with Google
      </button>
    </section>
  </main>
  <div class="fixed inset-0 flex items-center justify-center z-50" v-if="isAuthLoading">
    <div class="absolute inset-0 bg-slate-400/55 dark:bg-slate-900/65 backdrop-blur-sm"></div>
    <div class="relative bg-base-100 p-5 rounded-lg shadow-lg">
      <div class="flex items-center justify-center mb-3">
        <svg class="animate-spin h-6 w-6 mr-3 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
        <span>Loading...</span>
      </div>
      <p class="text-center text-sm text-content-base">Please wait while the content is loading.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import bgImg from "@/assets/img/openart-image_AimIJmGr_1720579260502_raw.jpg"
import { Hub } from "aws-amplify/utils";
import { useMyUserStore } from "~/store/user";
import { useMyAppStore } from "~/store/app";
const userStore = useMyUserStore();
import { getCurrentUser } from 'aws-amplify/auth';
const appStore = useMyAppStore();
const handleSignIn = () => {
  try {
    userStore.signInWithGoogle();
  } catch (error) {
    if (error === "NotAuthorizedException") {
      appStore.error({
        text: "Sorry, You dont have access to visit this page. Please sign in with your oneorigin google account.",
      });
    } else if (error === "UserAlreadyAuthenticatedException: There is already a signed in user.") {
      navigateTo("/jobs");
    }
  }
};
const isAuthLoading = ref(false);
watchEffect(() => {
  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      case "signInWithRedirect_failure":
        appStore.error({
          text: "Sorry, You dont have access to visit this page. Please sign in with your oneorigin google account.",
        });
        break;
    }

  });
  onBeforeMount(async () => {
    isAuthLoading.value = true;
    try {
      await getCurrentUser();
      navigateTo("/dashboard");
      isAuthLoading.value = false;
    } catch (error) {
      console.log(error);
      isAuthLoading.value = false;
    }
  });
})

</script>

<style>
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
</style>